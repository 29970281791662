import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import AppStoreBadge from "../assets/badge-appstore-white.svg";

export default function Home() {
  return (
    <Layout>
      <header></header>
      <Link to="https://apps.apple.com/us/app/tap-tap-cap/id1532782749"><AppStoreBadge className="object-center mx-auto my-8" /></Link>
    </Layout>
  )
}
